import React from 'react';
import './404Page.css'

const ErrorPage = () => {
    return (
        <div className="errorDiv">
            <h1 className="errorText">404 PAGE NOT FOUND</h1>
        </div>

    )
}

export default ErrorPage
